import { useMutation } from '@tanstack/react-query'
import { usePluginRegistry } from 'plugin-system'
import {
  ChallengeOutput,
  CreateChallengeInput,
  VerifyChallengeSignatureInput,
} from '../sdks/sdk-platform'
import {
  CreateChallengeFailureEvent,
  CreateChallengeSuccessEvent,
  VerifySignatureFailureEvent,
  VerifySignatureSuccessEvent,
} from '../events'
import { useApi } from './useApi'
import { useInvalidateResourceQuery } from './useResourceQuery'

export const useCreateChallengeAuthed = () => {
  const { coreApi } = useApi()
  const pluginRegistry = usePluginRegistry()

  return useMutation({
    mutationFn: ({ body }: { body: CreateChallengeInput }) =>
      coreApi.challengePost(body).then(res => res?.data) as any,
    onSuccess: (_: ChallengeOutput) => {
      pluginRegistry.events.publish(
        new CreateChallengeSuccessEvent({
          type: 'CREATE',
          resource: 'CHALLENGE',
        }),
      )
    },
    onError: (error, variables) => {
      pluginRegistry.events.publish(
        new CreateChallengeFailureEvent({
          type: 'CREATE',
          resource: 'CHALLENGE',
          error,
          resourceId: variables.body.address,
        }),
      )
    },
  })
}

export const useVerifySignatureAuthed = () => {
  const { coreApi } = useApi()
  const pluginRegistry = usePluginRegistry()
  const invalidate = useInvalidateResourceQuery()

  return useMutation({
    mutationFn: ({ body }: { body: VerifyChallengeSignatureInput }) =>
      coreApi.challengeVerifySignaturePost(body).then(res => res?.data) as any,
    onSuccess: (_: ChallengeOutput) => {
      pluginRegistry.events.publish(
        new VerifySignatureSuccessEvent({
          type: 'CREATE',
          resource: 'SIGNATURE_VERIFICATION',
        }),
      )
      invalidate('ORGANIZATION', 'ME')
    },
    onError: (error, variables) => {
      pluginRegistry.events.publish(
        new VerifySignatureFailureEvent({
          type: 'CREATE',
          resource: 'SIGNATURE_VERIFICATION',
          error,
          resourceId: variables.body.challenge_id,
        }),
      )
    },
  })
}
