import { QueryFunction, UseQueryOptions, UseQueryResult, useQuery, useQueryClient } from '@tanstack/react-query'
import { AxiosResponse } from 'axios'
import { PaginatedOutput, ApiResourceQueryKey, ApiResource } from '../types'

// TODO: fix query options type
export function useResourceQuery<T>(
  queryKey: ApiResourceQueryKey,
  queryFn: QueryFunction<AxiosResponse<T, any>, ApiResourceQueryKey>,
  queryOptions?: Omit<
    UseQueryOptions<AxiosResponse<T>, unknown, T, ApiResourceQueryKey>,
    'queryKey' | 'queryFn'
  >,
) : UseQueryResult<T, unknown> {
  const result = useQuery<AxiosResponse<T>, unknown, T, ApiResourceQueryKey>({
    queryKey,
    queryFn,
    ...(queryOptions ?? {})
  })

  return {
    ...result,
    isPending: result.isPending && queryOptions?.enabled !== false && !result.isError,
  } as UseQueryResult<T, unknown>
}

export function usePaginatedResourceQuery<T, TData = PaginatedOutput<T>>(
  queryKey: ApiResourceQueryKey,
  queryFn: QueryFunction<AxiosResponse<T, any>, ApiResourceQueryKey>,
  queryOptions?: Omit<
    UseQueryOptions<AxiosResponse<T>, unknown, TData, ApiResourceQueryKey>,
    'queryKey' | 'queryFn'
  >,
) {
  return useQuery<AxiosResponse<T>, unknown, TData, ApiResourceQueryKey>({
    queryKey,
    queryFn,
    ...(queryOptions ?? {}),
  })
}

export function useInvalidateResourceQuery() {
  const queryClient = useQueryClient()
  return async (resource: ApiResource, ...otherQueryKeyElements: any[]) => {
    if (otherQueryKeyElements.length > 0) {
      return queryClient.invalidateQueries({ queryKey: [resource, ...otherQueryKeyElements] })
    } else {
      // invalidate all queries for this resource even if not the first element in query key
      return queryClient.invalidateQueries({
        predicate: query => query.queryKey.includes(resource),
      })
    }
  }
}
