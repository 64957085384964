var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};

// src/plugins/profile-edit/Plugin.tsx
import { Plugin } from "plugin-system";
import { PORTAL_APP_BAR_USER_PROFILE_SLOT } from "common-ui";

// src/components/profile-edit/CreatorProfileEdit.tsx
import { useEffect, useState as useState2 } from "react";
import {
  useApi,
  useEditOrgNotificationSettings,
  useEditOrgProfile,
  useGetOrgNotificationSettings as useGetOrgNotificationSettings2,
  useOrgProfile
} from "common-api";
import { NotificationEvent, useAuth as useAuth2, useFeatureFlags as useFeatureFlags2, useMobileEnabled } from "common-ui";
import { useQueryClient } from "@tanstack/react-query";
import { subscribe } from "valtio";

// ../../common/api/src/sdks/sdk-platform-beta.ts
import globalAxios2 from "axios";

// ../../common/api/src/sdks/base.ts
import globalAxios from "axios";

// ../../common/api/src/sdks/sdk-platform-beta.ts
var EditOrganizationProfileInputCreatorRolesEnum = {
  Artist: "ARTIST",
  ContentCreator: "CONTENT_CREATOR",
  EventOrganizer: "EVENT_ORGANIZER",
  CommunityManager: "COMMUNITY_MANAGER",
  NoneApply: "NONE_APPLY"
};
var OrganizationProfileOutputCreatorRolesEnum = {
  Artist: "ARTIST",
  ContentCreator: "CONTENT_CREATOR",
  EventOrganizer: "EVENT_ORGANIZER",
  CommunityManager: "COMMUNITY_MANAGER",
  NoneApply: "NONE_APPLY"
};

// src/components/profile-edit/CreatorProfileEdit.tsx
import { usePluginRegistry } from "plugin-system";
import { ListItemButton, Typography as Typography2 } from "@mui/material";

// src/components/profile-edit/CreatorProfileEditForm.tsx
import {
  Dialog,
  DialogContent,
  Stack,
  Typography,
  IconButton,
  Box,
  TextField as TextField2,
  Button,
  DialogTitle,
  DialogActions,
  CircularProgress,
  InputAdornment,
  Checkbox as Checkbox2
} from "@mui/material";
import { toFormikValidationSchema } from "zod-formik-adapter";
import CloseIcon from "@mui/icons-material/Close";
import {
  CommonInputLabelStack,
  SingleFileServerUpload,
  FORM_PREVENT_CANCEL,
  LoadingButton,
  FORM_PREVENT_SUBMIT_LOADING,
  FORM_PREVENT_SUBMIT,
  useFeatureFlags
} from "common-ui";
import { COMMON_IMAGE_MIME_TYPES, useAuth } from "common-ui";
import { Formik } from "formik";
import { useSnapshot } from "valtio";
import {
  useCreatorProfilePageUri,
  useGetOrgNotificationSettings,
  useValidateOrgSlugAvailability
} from "common-api";

// src/components/CreatorRoleAutocomplete.tsx
import { Autocomplete, Checkbox, Chip, TextField } from "@mui/material";
import { useMemo, useState } from "react";
import { startCase } from "lodash";
import { jsx } from "react/jsx-runtime";
import { createElement } from "react";
var CreatorRoleAutocomplete = ({
  initialValues,
  onChange,
  size = "medium"
}) => {
  const [activeValues, setActiveValues] = useState(
    initialValues != null ? initialValues : []
  );
  const options = useMemo(() => {
    return Object.values(EditOrganizationProfileInputCreatorRolesEnum).map((role) => {
      return { value: role, label: startCase(role.toLowerCase().replace("_", " ")) };
    });
  }, []);
  const activeOptions = useMemo(() => {
    if (!(options == null ? void 0 : options.length) || !(activeValues == null ? void 0 : activeValues.length))
      return [];
    return activeValues.map((value) => {
      return options.find((option) => option.value === value);
    }).filter(Boolean);
  }, [activeValues, options]);
  return /* @__PURE__ */ jsx(
    Autocomplete,
    {
      multiple: true,
      size,
      id: "creator-role-autocomplete",
      options,
      disableCloseOnSelect: true,
      "data-testid": "creator-role-autocomplete",
      getOptionLabel: (option) => option.label,
      renderOption: (props, option, { selected }) => /* @__PURE__ */ createElement("li", __spreadProps(__spreadValues({}, props), { key: option == null ? void 0 : option.value }), /* @__PURE__ */ jsx(Checkbox, { style: { marginRight: 8 }, checked: selected }), option.label),
      renderTags: (value, getTagProps) => value.map((option, index) => /* @__PURE__ */ createElement(
        Chip,
        __spreadProps(__spreadValues({
          color: "secondary",
          variant: "filled",
          label: `${option.label}`
        }, getTagProps({ index })), {
          key: index
        })
      )),
      onChange: (_, chosenOpts) => {
        const selectedNoneApply = chosenOpts.some(
          (option) => option.value === EditOrganizationProfileInputCreatorRolesEnum.NoneApply
        );
        const newOptValues = selectedNoneApply ? [EditOrganizationProfileInputCreatorRolesEnum.NoneApply] : chosenOpts.map(
          (option) => option.value
        );
        setActiveValues(newOptValues);
        onChange(newOptValues);
      },
      value: activeOptions,
      sx: {
        ".MuiInputBase-root": { height: "auto", pb: 1 },
        flexGrow: 1
      },
      renderInput: (params) => /* @__PURE__ */ jsx(
        TextField,
        __spreadProps(__spreadValues({}, params), {
          label: "Self-Identification",
          InputProps: __spreadProps(__spreadValues({}, params.InputProps), {
            inputProps: __spreadProps(__spreadValues({}, params.inputProps), {
              placeholder: activeValues.length === 0 ? "Choose the closest match to you, as a creator" : ""
              // Placeholder logic
            })
          })
        })
      )
    }
  );
};

// src/state/profile-edit/index.ts
import { proxy } from "valtio";
import * as z from "zod";
var validationSchema = (validateSlugAvailability, originalSlug) => z.object({
  bannerUrl: z.string().min(1).optional(),
  avatarUrl: z.string().min(1).optional(),
  displayName: z.string().min(1).max(32).optional(),
  creatorSlug: z.string({ required_error: "Username is required." }).min(5, { message: "Username must contain at least 5 characters" }).max(75).regex(
    /^[a-zA-Z0-9._-]+$/,
    "Username can only contain letters, numbers, underscores, periods and hyphens."
  ).refine(
    (val) => __async(void 0, null, function* () {
      if (val === originalSlug)
        return true;
      const { isAvailable } = yield validateSlugAvailability(val);
      if (isAvailable) {
        return true;
      }
      return false;
    }),
    { message: "The username is not available for use." }
  ),
  creatorBio: z.string().min(1).max(140).optional(),
  oldWebsiteLink: z.string().optional(),
  websiteLink: z.string().url().optional(),
  instagramLink: z.string().min(1).refine((value) => /^.*instagram\.com\/[\w.]+$/i.test(value), {
    message: "Please enter a valid Instagram profile link."
  }).optional(),
  twitterLink: z.string().min(1).refine((value) => /^.*(twitter|x)\.com\/\w+$/i.test(value), {
    message: "Please enter a valid X profile link."
  }).optional(),
  discordLink: z.string().min(1).refine((value) => /^.*discord\.(gg|com)\/.+$/i.test(value), {
    message: "Please enter a valid Discord invite link."
  }).optional(),
  creatorRoles: z.array(
    z.enum([
      EditOrganizationProfileInputCreatorRolesEnum.Artist,
      EditOrganizationProfileInputCreatorRolesEnum.CommunityManager,
      EditOrganizationProfileInputCreatorRolesEnum.ContentCreator,
      EditOrganizationProfileInputCreatorRolesEnum.ContentCreator,
      EditOrganizationProfileInputCreatorRolesEnum.EventOrganizer,
      EditOrganizationProfileInputCreatorRolesEnum.NoneApply
    ])
  ).optional(),
  marketingUpdatesConsent: z.boolean().optional()
}).superRefine((val, ctx) => {
  if (!val.websiteLink && val.oldWebsiteLink) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: "Website link cannot be cleared once set.",
      path: ["websiteLink"]
    });
  }
});
var defaultState = {
  open: false,
  displayName: "",
  bannerUrl: "",
  avatarUrl: "",
  creatorBio: "",
  creatorSlug: "",
  websiteLink: "",
  instagramLink: "",
  twitterLink: "",
  discordLink: "",
  marketingUpdatesConsent: false
};
var setOrganizationProfileData = (data) => {
  var _a, _b, _c, _d, _e, _f, _g, _h, _i, _j, _k, _l, _m;
  state.displayName = (_a = data == null ? void 0 : data.data) == null ? void 0 : _a.public_name;
  state.bannerUrl = (_c = (_b = data == null ? void 0 : data.data) == null ? void 0 : _b.media) == null ? void 0 : _c.banner;
  state.avatarUrl = (_e = (_d = data == null ? void 0 : data.data) == null ? void 0 : _d.media) == null ? void 0 : _e.avatar;
  state.creatorBio = (_f = data == null ? void 0 : data.data) == null ? void 0 : _f.description;
  state.creatorSlug = (_g = data == null ? void 0 : data.data) == null ? void 0 : _g.slug;
  state.instagramLink = (_i = (_h = data == null ? void 0 : data.data) == null ? void 0 : _h.socials) == null ? void 0 : _i.instagram;
  state.twitterLink = (_k = (_j = data == null ? void 0 : data.data) == null ? void 0 : _j.socials) == null ? void 0 : _k.twitter;
  state.discordLink = (_m = (_l = data == null ? void 0 : data.data) == null ? void 0 : _l.socials) == null ? void 0 : _m.discord;
};
var state = proxy(defaultState);
function openProfileEditModal() {
  state.open = true;
}
function closeProfileEditModal() {
  state.open = false;
}
var profile_edit_default = state;

// src/components/profile-edit/CreatorProfileEditForm.tsx
import { Fragment, jsx as jsx2, jsxs } from "react/jsx-runtime";
var CreatorProfileEditForm = ({
  open,
  handleClose,
  handleSubmit,
  organizationProfile,
  axios
}) => {
  var _a, _b, _c, _d, _e, _f, _g, _h;
  const { platform } = useAuth();
  const { onboardingMarketingUpdatesConsent } = useFeatureFlags();
  const { data: notificationSettings } = useGetOrgNotificationSettings();
  const { uri: creatorProfilePageUri, isPending: creatorProfilePageUriPending } = useCreatorProfilePageUri(open ? (_a = platform == null ? void 0 : platform.org) == null ? void 0 : _a.id : void 0);
  const defaultState2 = useSnapshot(profile_edit_default);
  const fileUploadUri = axios ? (axios == null ? void 0 : axios.defaults.baseURL) + "/uploads" : "";
  const handleOpenNewWindow = () => {
    window.open(creatorProfilePageUri, "_blank", "noopener,noreferrer");
  };
  const { mutateAsync: validateSlugAvailability } = useValidateOrgSlugAvailability();
  if (creatorProfilePageUriPending) {
    return /* @__PURE__ */ jsx2(Fragment, {});
  }
  return /* @__PURE__ */ jsx2(
    Formik,
    {
      enableReinitialize: true,
      validateOnBlur: false,
      initialValues: {
        bannerUrl: defaultState2.bannerUrl || ((_b = organizationProfile == null ? void 0 : organizationProfile.media) == null ? void 0 : _b.banner) || "",
        avatarUrl: defaultState2.avatarUrl || ((_c = organizationProfile == null ? void 0 : organizationProfile.media) == null ? void 0 : _c.avatar) || "",
        displayName: defaultState2.displayName || (organizationProfile == null ? void 0 : organizationProfile.public_name) || "",
        creatorBio: defaultState2.creatorBio || (organizationProfile == null ? void 0 : organizationProfile.description) || "",
        creatorSlug: defaultState2.creatorSlug || (organizationProfile == null ? void 0 : organizationProfile.slug) || "",
        oldWebsiteLink: defaultState2.websiteLink || (organizationProfile == null ? void 0 : organizationProfile.website) || "",
        websiteLink: defaultState2.websiteLink || (organizationProfile == null ? void 0 : organizationProfile.website) || "",
        instagramLink: defaultState2.instagramLink || ((_d = organizationProfile == null ? void 0 : organizationProfile.socials) == null ? void 0 : _d.instagram) || "",
        twitterLink: defaultState2.twitterLink || ((_e = organizationProfile == null ? void 0 : organizationProfile.socials) == null ? void 0 : _e.twitter) || "",
        discordLink: defaultState2.discordLink || ((_f = organizationProfile == null ? void 0 : organizationProfile.socials) == null ? void 0 : _f.discord) || "",
        creatorRoles: defaultState2.creatorRoles || (organizationProfile == null ? void 0 : organizationProfile.creator_roles) || [],
        organizationId: ((_g = platform == null ? void 0 : platform.org) == null ? void 0 : _g.id) || "",
        marketingUpdatesConsent: (notificationSettings == null ? void 0 : notificationSettings.marketing_updates_consent) || false,
        preventCancel: false,
        preventSubmit: false,
        preventSubmitLoading: false
      },
      validationSchema: toFormikValidationSchema(
        validationSchema(validateSlugAvailability, (_h = organizationProfile == null ? void 0 : organizationProfile.slug) != null ? _h : void 0)
      ),
      onSubmit: (_values, actions) => {
        actions.submitForm();
      },
      children: (props) => /* @__PURE__ */ jsx2("form", { children: /* @__PURE__ */ jsxs(
        Dialog,
        {
          sx: {
            ".MuiDialog-paper": { minHeight: 650, minWidth: 650 }
          },
          open,
          onClose: handleClose,
          children: [
            /* @__PURE__ */ jsxs(DialogTitle, { sx: { justifyContent: "space-between" }, children: [
              /* @__PURE__ */ jsxs(Stack, { sx: { flex: 1, display: "flex", flexDirection: "column", rowGap: 1 }, children: [
                /* @__PURE__ */ jsx2(Typography, { variant: "h4", children: "Update Phosphor Studio Profile" }),
                /* @__PURE__ */ jsx2(Typography, { variant: "body1", children: "Update your profile with a few details about yourself." })
              ] }),
              /* @__PURE__ */ jsx2(IconButton, { "aria-label": "close", onClick: handleClose, size: "large", children: /* @__PURE__ */ jsx2(CloseIcon, { fontSize: "small" }) })
            ] }),
            /* @__PURE__ */ jsx2(
              DialogContent,
              {
                sx: {
                  display: "flex",
                  flexDirection: "column"
                },
                children: /* @__PURE__ */ jsxs(
                  Stack,
                  {
                    sx: {
                      flex: 2,
                      display: "flex",
                      flexDirection: "column",
                      rowGap: 1,
                      backgroundColor: "white",
                      color: "black"
                    },
                    children: [
                      /* @__PURE__ */ jsxs(Box, { children: [
                        /* @__PURE__ */ jsx2(
                          CommonInputLabelStack,
                          {
                            label: "Profile picture and banner",
                            isOptional: true,
                            forCtrl: "custom"
                          }
                        ),
                        /* @__PURE__ */ jsxs(
                          Stack,
                          {
                            sx: {
                              position: "relative",
                              width: "100%",
                              marginBottom: "4em !important",
                              marginTop: "5px !important"
                            },
                            children: [
                              /* @__PURE__ */ jsx2(
                                Box,
                                {
                                  sx: {
                                    backgroundColor: (theme) => theme.palette.shades.white,
                                    borderRadius: "5px",
                                    opacity: "95%"
                                  },
                                  children: /* @__PURE__ */ jsx2(
                                    SingleFileServerUpload,
                                    {
                                      testId: "profile-banner-upload",
                                      height: "150px",
                                      width: "100%",
                                      maxFileSize: "2MB",
                                      initialValue: props.values.bannerUrl,
                                      disabled: props.isSubmitting,
                                      acceptedFileTypes: COMMON_IMAGE_MIME_TYPES,
                                      titleLabel: "Upload a profile banner",
                                      subtitleLabel: "",
                                      captionLabel: ``,
                                      uploadUri: fileUploadUri,
                                      uploadAuthHeader: axios == null ? void 0 : axios.defaults.headers.Authorization,
                                      onFileUploadedUrlChange: (organizationBannerUrl) => {
                                        props.setFieldValue("bannerUrl", organizationBannerUrl, true);
                                        props.setFieldTouched("bannerUrl", true, false);
                                      },
                                      onUploadingChange: (isUploading) => {
                                        props.setFieldValue(FORM_PREVENT_SUBMIT_LOADING, isUploading, false);
                                        props.setFieldValue(FORM_PREVENT_CANCEL, isUploading, false);
                                      }
                                    }
                                  )
                                }
                              ),
                              /* @__PURE__ */ jsx2(
                                Box,
                                {
                                  sx: {
                                    position: "absolute",
                                    top: "35%",
                                    left: "3%",
                                    backgroundColor: (theme) => theme.palette.shades.white,
                                    borderRadius: "5px"
                                  },
                                  children: /* @__PURE__ */ jsx2(
                                    SingleFileServerUpload,
                                    {
                                      testId: "profile-avatar-upload",
                                      height: "150px",
                                      width: "150px",
                                      maxFileSize: "2MB",
                                      initialValue: props.values.avatarUrl,
                                      disabled: props.isSubmitting,
                                      acceptedFileTypes: COMMON_IMAGE_MIME_TYPES,
                                      titleLabel: "Upload avatar",
                                      subtitleLabel: "",
                                      captionLabel: ``,
                                      uploadUri: fileUploadUri,
                                      uploadAuthHeader: axios == null ? void 0 : axios.defaults.headers.Authorization,
                                      onFileUploadedUrlChange: (organizationBannerUrl) => {
                                        props.setFieldValue("avatarUrl", organizationBannerUrl, true);
                                        props.setFieldTouched("avatarUrl", true, false);
                                      },
                                      onUploadingChange: (isUploading) => {
                                        props.setFieldValue(FORM_PREVENT_SUBMIT_LOADING, isUploading, false);
                                        props.setFieldValue(FORM_PREVENT_CANCEL, isUploading, false);
                                      },
                                      allowImageCrop: true,
                                      imageCropAspectRatio: "1:1"
                                    }
                                  )
                                }
                              )
                            ]
                          }
                        )
                      ] }),
                      /* @__PURE__ */ jsx2(
                        TextField2,
                        {
                          fullWidth: true,
                          id: "displayName",
                          name: "displayName",
                          type: "text",
                          inputProps: {
                            readOnly: props.isSubmitting
                          },
                          "data-testid": "profile-edit-display-name",
                          label: /* @__PURE__ */ jsx2(CommonInputLabelStack, { label: "Display Name", isOptional: true }),
                          autoFocus: true,
                          value: props.values.displayName,
                          onChange: props.handleChange,
                          onBlur: props.handleBlur,
                          error: props.touched["displayName"] && Boolean(props.errors["displayName"]),
                          disabled: props.isSubmitting,
                          helperText: props.touched["displayName"] && props.errors["displayName"]
                        }
                      ),
                      /* @__PURE__ */ jsx2(
                        TextField2,
                        {
                          fullWidth: true,
                          id: "creatorSlug",
                          name: "creatorSlug",
                          type: "text",
                          inputProps: {
                            readOnly: props.isSubmitting
                          },
                          "data-testid": "profile-edit-creator-slug",
                          label: /* @__PURE__ */ jsx2(CommonInputLabelStack, { label: "Username" }),
                          autoFocus: true,
                          value: props.values.creatorSlug,
                          onChange: props.handleChange,
                          onBlur: props.handleBlur,
                          error: props.touched["creatorSlug"] && Boolean(props.errors["creatorSlug"]),
                          disabled: props.isSubmitting,
                          InputProps: props.values[FORM_PREVENT_SUBMIT] && !props.errors["creatorSlug"] ? {
                            endAdornment: /* @__PURE__ */ jsx2(InputAdornment, { position: "end", children: /* @__PURE__ */ jsx2(CircularProgress, { size: 18 }) })
                          } : /* @__PURE__ */ jsx2(Fragment, {}),
                          helperText: props.touched["creatorSlug"] && /* @__PURE__ */ jsx2(Fragment, { children: props.errors["creatorSlug"] ? props.errors["creatorSlug"] : /* @__PURE__ */ jsx2(Fragment, { children: "Once you changed the username, all your previous profile links will cease to work. This might take 1-2 min to take effect." }) })
                        }
                      ),
                      /* @__PURE__ */ jsx2(
                        TextField2,
                        {
                          fullWidth: true,
                          id: "creatorBio",
                          name: "creatorBio",
                          type: "text",
                          inputProps: {
                            readOnly: props.isSubmitting
                          },
                          "data-testid": "profile-edit-bio",
                          label: /* @__PURE__ */ jsx2(CommonInputLabelStack, { label: "Bio", isOptional: true }),
                          value: props.values.creatorBio,
                          onChange: props.handleChange,
                          onBlur: props.handleBlur,
                          error: props.touched["creatorBio"] && Boolean(props.errors["creatorBio"]),
                          disabled: props.isSubmitting,
                          helperText: props.touched["creatorBio"] && props.errors["creatorBio"]
                        }
                      ),
                      /* @__PURE__ */ jsx2(
                        TextField2,
                        {
                          fullWidth: true,
                          id: "websiteLink",
                          name: "websiteLink",
                          type: "text",
                          inputProps: {
                            readOnly: props.isSubmitting
                          },
                          "data-testid": "profile-edit-website-link",
                          label: /* @__PURE__ */ jsx2(CommonInputLabelStack, { label: "Website link", isOptional: true }),
                          value: props.values["websiteLink"],
                          onChange: props.handleChange,
                          onBlur: props.handleBlur,
                          error: props.touched["websiteLink"] && Boolean(props.errors["websiteLink"]),
                          disabled: props.isSubmitting,
                          helperText: props.touched["websiteLink"] && props.errors["websiteLink"]
                        }
                      ),
                      /* @__PURE__ */ jsx2(Typography, { variant: "body1", sx: { my: 1 }, children: "Social Links" }),
                      /* @__PURE__ */ jsx2(
                        TextField2,
                        {
                          fullWidth: true,
                          id: "instagramLink",
                          name: "instagramLink",
                          type: "text",
                          "data-testid": "profile-edit-instagram-link",
                          label: /* @__PURE__ */ jsx2(CommonInputLabelStack, { label: "Instagram", isOptional: true }),
                          value: props.values["instagramLink"],
                          onChange: props.handleChange,
                          onBlur: props.handleBlur,
                          inputProps: {
                            readOnly: props.isSubmitting
                          },
                          error: props.touched["instagramLink"] && Boolean(props.errors["instagramLink"]),
                          disabled: props.isSubmitting,
                          helperText: props.touched["instagramLink"] && props.errors["instagramLink"]
                        }
                      ),
                      /* @__PURE__ */ jsx2(
                        TextField2,
                        {
                          fullWidth: true,
                          id: "twitterLink",
                          name: "twitterLink",
                          type: "text",
                          inputProps: {
                            readOnly: props.isSubmitting
                          },
                          "data-testid": "profile-edit-twitter-link",
                          label: /* @__PURE__ */ jsx2(CommonInputLabelStack, { label: "X", isOptional: true }),
                          value: props.values["twitterLink"],
                          onChange: props.handleChange,
                          onBlur: props.handleBlur,
                          error: props.touched["twitterLink"] && Boolean(props.errors["twitterLink"]),
                          disabled: props.isSubmitting,
                          helperText: props.touched["twitterLink"] && props.errors["twitterLink"]
                        }
                      ),
                      /* @__PURE__ */ jsx2(
                        TextField2,
                        {
                          fullWidth: true,
                          id: "discordLink",
                          name: "discordLink",
                          type: "text",
                          inputProps: {
                            readOnly: props.isSubmitting
                          },
                          "data-testid": "profile-edit-discord-link",
                          label: /* @__PURE__ */ jsx2(CommonInputLabelStack, { label: "Discord Invite", isOptional: true }),
                          value: props.values["discordLink"],
                          onChange: props.handleChange,
                          onBlur: props.handleBlur,
                          error: props.touched["discordLink"] && Boolean(props.errors["discordLink"]),
                          disabled: props.isSubmitting,
                          helperText: props.touched["discordLink"] && props.errors["discordLink"]
                        }
                      ),
                      /* @__PURE__ */ jsx2(
                        CreatorRoleAutocomplete,
                        {
                          initialValues: props.values.creatorRoles,
                          onChange: (values) => {
                            props.setFieldValue("creatorRoles", values != null ? values : []);
                          }
                        }
                      ),
                      onboardingMarketingUpdatesConsent && /* @__PURE__ */ jsxs(Box, { display: "flex", alignItems: "center", children: [
                        /* @__PURE__ */ jsx2(
                          Checkbox2,
                          {
                            checked: props.values.marketingUpdatesConsent,
                            id: "marketingUpdatesConsent",
                            name: "marketingUpdatesConsent",
                            onChange: props.handleChange,
                            onBlur: props.handleBlur,
                            "data-testid": "notification-settings-checkbox"
                          }
                        ),
                        /* @__PURE__ */ jsx2(Typography, { variant: "body2", children: "Keep me informed with updates and special announcements from Phosphor" })
                      ] })
                    ]
                  }
                )
              }
            ),
            /* @__PURE__ */ jsxs(DialogActions, { children: [
              /* @__PURE__ */ jsx2(
                Button,
                {
                  "data-testid": "back",
                  variant: "outlined",
                  sx: {
                    "&.Mui-disabled": {
                      background: "#eaeaea",
                      color: "#c0c0c0"
                    },
                    color: "black ",
                    border: "1px solid black "
                  },
                  onClick: handleOpenNewWindow,
                  children: "View Profile"
                }
              ),
              /* @__PURE__ */ jsx2(
                LoadingButton,
                {
                  "data-testid": "next",
                  variant: "contained",
                  type: "submit",
                  sx: {
                    "&.Mui-disabled": {
                      background: "#eaeaea",
                      color: "#c0c0c0"
                    },
                    color: "black"
                  },
                  loading: props.isSubmitting || props.values[FORM_PREVENT_SUBMIT_LOADING],
                  title: props.values[FORM_PREVENT_SUBMIT_LOADING] ? "This button is disabled because there is an action taking place on the form that has yet to complete." : void 0,
                  loadingText: props.isSubmitting ? "Saving..." : "Waiting...",
                  disabled: props.isSubmitting || props.values[FORM_PREVENT_SUBMIT] === true || props.values[FORM_PREVENT_SUBMIT_LOADING] === true || !props.isValid,
                  onClick: () => handleSubmit == null ? void 0 : handleSubmit(props.values),
                  children: "Update"
                }
              )
            ] })
          ]
        }
      ) })
    }
  );
};

// src/assets/icons/updateProfile.svg
import * as React from "react";
import { jsx as jsx3 } from "react/jsx-runtime";
var SvgUpdateProfile = (props) => /* @__PURE__ */ jsx3("svg", __spreadProps(__spreadValues({ width: 18, height: 18, viewBox: "0 0 18 18", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, props), { children: /* @__PURE__ */ jsx3("path", { d: "M13.0514 2.73889L14.4576 1.33265C15.0678 0.722451 16.0572 0.722451 16.6674 1.33265C17.2775 1.94284 17.2775 2.93216 16.6674 3.54235L4.69349 15.5162C4.25292 15.9568 3.70953 16.2806 3.11241 16.4585L0.875 17.125L1.54148 14.8876C1.71936 14.2905 2.04321 13.7471 2.48377 13.3065L13.0514 2.73889ZM13.0514 2.73889L15.25 4.93749", stroke: "#27272A", strokeWidth: 1.5, strokeLinecap: "round", strokeLinejoin: "round" }) }));
var updateProfile_default = SvgUpdateProfile;

// src/components/profile-edit/CreatorProfileEdit.tsx
import { Fragment as Fragment2, jsx as jsx4, jsxs as jsxs2 } from "react/jsx-runtime";
function CreatorProfileEdit() {
  const isMobile = useMobileEnabled();
  const pluginRegistry = usePluginRegistry();
  const { platform } = useAuth2();
  const [open, setOpen] = useState2(false);
  const queryClient = useQueryClient();
  const { axios } = useApi();
  const organizationId = platform == null ? void 0 : platform.org.id;
  const {
    mutateAsync: saveOrgProfile,
    isSuccess: savedOrgProfile,
    reset: resetSaveOrgProfile,
    isError: saveOrgProfileError
  } = useEditOrgProfile(organizationId);
  const { data: organizationProfile, isPending } = useOrgProfile(organizationId);
  const { onboardingMarketingUpdatesConsent } = useFeatureFlags2();
  const { data: notificationSettings, isSuccess } = useGetOrgNotificationSettings2();
  const { mutateAsync: updateEmailNotificationSettings } = useEditOrgNotificationSettings();
  useEffect(() => {
    if (!savedOrgProfile)
      return;
    pluginRegistry.events.publish(
      new NotificationEvent({
        message: "Profile updated successfully",
        options: {
          type: "success",
          toastId: "profile-update-success"
        }
      })
    );
    if (resetSaveOrgProfile)
      resetSaveOrgProfile();
  }, [savedOrgProfile, pluginRegistry, resetSaveOrgProfile]);
  useEffect(() => {
    if (!saveOrgProfileError)
      return;
    pluginRegistry.events.publish(
      new NotificationEvent({
        message: "Profile update failed, please try again. If this problem persists, please contact support.",
        contactSupport: true,
        options: {
          type: "error",
          toastId: "profile-update-failed"
        }
      })
    );
    if (resetSaveOrgProfile && saveOrgProfileError)
      resetSaveOrgProfile();
  }, [saveOrgProfileError, pluginRegistry, resetSaveOrgProfile]);
  const handleOpen = () => {
    queryClient.invalidateQueries({ queryKey: ["ORGANIZATION_PROFILE", organizationId] });
    openProfileEditModal();
  };
  const handleClose = () => {
    closeProfileEditModal();
  };
  useEffect(() => {
    subscribe(profile_edit_default, () => {
      if (profile_edit_default.open) {
        setOpen(true);
      } else {
        setOpen(false);
      }
    });
  }, []);
  const handleSubmit = (values) => __async(this, null, function* () {
    var _a;
    try {
      const saveOrgProfileVariables = {
        description: values.creatorBio || null,
        public_name: values.displayName || null,
        slug: values.creatorSlug,
        socials: {
          instagram: values.instagramLink || null,
          twitter: values.twitterLink || null,
          discord: values.discordLink || null
        },
        media: {
          banner: values.bannerUrl || null,
          avatar: values.avatarUrl || null
        },
        creator_roles: !((_a = values.creatorRoles) == null ? void 0 : _a.length) ? [OrganizationProfileOutputCreatorRolesEnum.NoneApply] : values.creatorRoles
      };
      if (values.websiteLink) {
        saveOrgProfileVariables.website = values.websiteLink;
      }
      if (onboardingMarketingUpdatesConsent && isSuccess && values.marketingUpdatesConsent !== notificationSettings.marketing_updates_consent) {
        yield updateEmailNotificationSettings({
          marketing_updates_consent: values.marketingUpdatesConsent
        });
      }
      yield saveOrgProfile(saveOrgProfileVariables);
      handleClose();
    } catch (e) {
    }
  });
  if (isMobile)
    return /* @__PURE__ */ jsx4(Fragment2, {});
  return /* @__PURE__ */ jsxs2(Fragment2, { children: [
    /* @__PURE__ */ jsxs2(
      ListItemButton,
      {
        sx: {
          display: "flex",
          flexDirection: "row"
        },
        onClick: handleOpen,
        children: [
          /* @__PURE__ */ jsx4(updateProfile_default, {}),
          /* @__PURE__ */ jsx4(Typography2, { fontWeight: "600", marginLeft: "12px", children: isPending ? "Getting Profile ..." : "Update Profile" })
        ]
      }
    ),
    !isPending && /* @__PURE__ */ jsx4(
      CreatorProfileEditForm,
      {
        open,
        handleClose,
        handleSubmit,
        organizationProfile,
        axios
      }
    )
  ] });
}

// src/components/AccountDetails.tsx
import { useCallback, useState as useState4 } from "react";
import { AuthClearEvent, useAuth as useAuth3, useFeatureFlags as useFeatureFlags3 } from "common-ui";
import {
  useAllPublicNetworks,
  useApi as useApi2,
  useEditOrgNotificationSettings as useEditOrgNotificationSettings2,
  useEditOrgProfile as useEditOrgProfile2,
  useGetOrgNotificationSettings as useGetOrgNotificationSettings3,
  useOrgProfile as useOrgProfile2,
  useRequestProfileClosure
} from "common-api";
import CloseIcon3 from "@mui/icons-material/Close";
import { useNavigate } from "react-router-dom";
import {
  Button as Button3,
  Dialog as Dialog3,
  DialogActions as DialogActions3,
  DialogContent as DialogContent3,
  DialogTitle as DialogTitle2,
  IconButton as IconButton3,
  ListItemButton as ListItemButton2,
  Stack as Stack3,
  Typography as Typography4
} from "@mui/material";
import { usePluginRegistry as usePluginRegistry2 } from "plugin-system";

// src/components/profile-delete/CreatorProfileDeleteForm.tsx
import { useState as useState3 } from "react";
import Dialog2 from "@mui/material/Dialog";
import { Box as Box2, Button as Button2, DialogActions as DialogActions2, DialogContent as DialogContent2, FormGroup, IconButton as IconButton2, Stack as Stack2, Typography as Typography3 } from "@mui/material";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import CloseIcon2 from "@mui/icons-material/Close";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox3 from "@mui/material/Checkbox";
import { jsx as jsx5, jsxs as jsxs3 } from "react/jsx-runtime";
var CreatorProfileDeleteForm = ({
  open,
  handleClose,
  handleDelete
}) => {
  const [deleteEnabled, setDeleteEnabled] = useState3(false);
  const handleAcknowledgmentClicked = () => {
    if (!deleteEnabled) {
      setDeleteEnabled(true);
    } else {
      setDeleteEnabled(false);
    }
  };
  return /* @__PURE__ */ jsx5(
    Dialog2,
    {
      sx: {
        ".MuiDialog-paper": { minHeight: 490, minWidth: 700 }
      },
      open,
      onClose: handleClose,
      children: /* @__PURE__ */ jsx5(Stack2, { children: /* @__PURE__ */ jsxs3(DialogContent2, { dividers: false, children: [
        /* @__PURE__ */ jsxs3(Box2, { sx: { display: "flex", flexDirection: "row", justifyContent: "space-between", marginBottom: 2 }, children: [
          /* @__PURE__ */ jsx5(IconButton2, { color: "error", "aria-label": "error", size: "large", style: { background: "#feebea" }, children: /* @__PURE__ */ jsx5(ErrorOutlineOutlinedIcon, { fontSize: "small" }) }),
          /* @__PURE__ */ jsx5(IconButton2, { "aria-label": "close", onClick: handleClose, size: "large", children: /* @__PURE__ */ jsx5(CloseIcon2, { fontSize: "small" }) })
        ] }),
        /* @__PURE__ */ jsx5(Typography3, { variant: "bodyL", children: "Deleting your profile is permanent and cannot be undone." }),
        /* @__PURE__ */ jsx5(Typography3, { variant: "body1", children: "By choosing to delete your profile, you acknowledge that:" }),
        /* @__PURE__ */ jsx5(Box2, { children: /* @__PURE__ */ jsx5(Typography3, { variant: "body1", children: /* @__PURE__ */ jsxs3("ul", { children: [
          /* @__PURE__ */ jsx5("li", { children: "All active and planned Drops will be ended" }),
          /* @__PURE__ */ jsx5("li", { children: "Your Drops and Collectibles will no longer be visible on Phosphor - blockchain records of your Collectibles will persist" }),
          /* @__PURE__ */ jsx5("li", { children: "Your login credentials will be disabled and deleted" }),
          /* @__PURE__ */ jsx5("li", { children: "Your username will be made available for future users" }),
          /* @__PURE__ */ jsx5("li", { children: "Your profile content will be removed" }),
          /* @__PURE__ */ jsx5("li", { children: "You will be removed from Phosphor-related marketing lists (if present)" }),
          /* @__PURE__ */ jsx5("li", { children: "Audience Lists, Snapshots and Follower Lists will be deleted" }),
          /* @__PURE__ */ jsx5("li", { children: "Your avatar, display name and user handle will no longer be present anywhere on the Phosphor platform" })
        ] }) }) }),
        /* @__PURE__ */ jsxs3(Stack2, { direction: "row", children: [
          /* @__PURE__ */ jsx5(FormGroup, { children: /* @__PURE__ */ jsx5(FormControlLabel, { control: /* @__PURE__ */ jsx5(Checkbox3, { onChange: handleAcknowledgmentClicked }), label: "" }) }),
          /* @__PURE__ */ jsx5(Typography3, { variant: "bodyL", children: "I have read the above and I understand that deleting my profile is permanent and cannot be undone." })
        ] }),
        /* @__PURE__ */ jsxs3(DialogActions2, { sx: { justifyContent: "end", margin: 0 }, children: [
          /* @__PURE__ */ jsx5(
            Button2,
            {
              onClick: handleDelete,
              color: "error",
              variant: "outlined",
              disabled: !deleteEnabled,
              sx: {
                "&:hover": {
                  color: "white !important",
                  background: "#ff0000"
                }
              },
              children: "Permanantly Delete My Profile"
            }
          ),
          /* @__PURE__ */ jsx5(
            Button2,
            {
              "data-testid": "next",
              variant: "contained",
              sx: {
                "&.Mui-disabled": {
                  background: "#eaeaea",
                  color: "#c0c0c0"
                },
                color: (theme) => `${theme.palette.primary.main} !important`,
                "&:hover": {
                  color: "white !important"
                }
              },
              onClick: handleClose,
              children: "Keep My Profile Open"
            }
          )
        ] })
      ] }) })
    }
  );
};

// src/assets/icons/accountDetails.svg
import * as React2 from "react";
import { jsx as jsx6, jsxs as jsxs4 } from "react/jsx-runtime";
var SvgAccountDetails = (props) => /* @__PURE__ */ jsxs4("svg", __spreadProps(__spreadValues({ width: 18, height: 13, viewBox: "0 0 18 13", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, props), { children: [
  /* @__PURE__ */ jsx6("path", { d: "M0.875 1.9375C0.875 1.41973 1.29473 1 1.8125 1H6.8125C7.33027 1 7.75 1.41973 7.75 1.9375V5.0625C7.75 5.58027 7.33027 6 6.8125 6H1.8125C1.29473 6 0.875 5.58027 0.875 5.0625V1.9375Z", stroke: "#27272A", strokeWidth: 1.5, strokeLinecap: "round", strokeLinejoin: "round" }),
  /* @__PURE__ */ jsx6("path", { d: "M10.875 3.1875C10.875 2.66973 11.2947 2.25 11.8125 2.25H16.1875C16.7053 2.25 17.125 2.66973 17.125 3.1875V10.0625C17.125 10.5803 16.7053 11 16.1875 11H11.8125C11.2947 11 10.875 10.5803 10.875 10.0625V3.1875Z", stroke: "#27272A", strokeWidth: 1.5, strokeLinecap: "round", strokeLinejoin: "round" }),
  /* @__PURE__ */ jsx6("path", { d: "M2.125 9.4375C2.125 8.91973 2.54473 8.5 3.0625 8.5H7.4375C7.95527 8.5 8.375 8.91973 8.375 9.4375V11.3125C8.375 11.8303 7.95527 12.25 7.4375 12.25H3.0625C2.54473 12.25 2.125 11.8303 2.125 11.3125V9.4375Z", stroke: "#27272A", strokeWidth: 1.5, strokeLinecap: "round", strokeLinejoin: "round" })
] }));
var accountDetails_default = SvgAccountDetails;

// src/components/AccountDetails.tsx
import { Fragment as Fragment3, jsx as jsx7, jsxs as jsxs5 } from "react/jsx-runtime";
function AccountDetails() {
  const [open, setOpen] = useState4(false);
  const [editProfileOpen, setEditProfileOpen] = useState4(false);
  const [deleteProfileOpen, setDeleteProfileOpen] = useState4(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setEditProfileOpen(false);
    setDeleteProfileOpen(false);
  };
  return /* @__PURE__ */ jsxs5(Fragment3, { children: [
    /* @__PURE__ */ jsxs5(
      ListItemButton2,
      {
        onClick: handleOpen,
        sx: {
          color: "primary.main",
          display: "flex",
          flexDirection: "row"
        },
        children: [
          /* @__PURE__ */ jsx7(accountDetails_default, {}),
          /* @__PURE__ */ jsx7(Typography4, { fontWeight: "600", marginLeft: "12px", children: "Account Details" })
        ]
      }
    ),
    /* @__PURE__ */ jsx7(
      AccountDetailsDialog,
      {
        open,
        handleClose,
        setEditProfileOpen,
        editProfileOpen,
        setDeleteProfileOpen,
        deleteProfileOpen
      }
    )
  ] });
}
function AccountDetailsRow({ label, value }) {
  return /* @__PURE__ */ jsxs5(Fragment3, { children: [
    /* @__PURE__ */ jsx7(Typography4, { variant: "bodyL", sx: { textAlign: "right" }, children: label }),
    /* @__PURE__ */ jsx7(Typography4, { variant: "body1", children: value })
  ] });
}
function AccountDetailsDialog({
  open,
  handleClose,
  setEditProfileOpen,
  editProfileOpen,
  setDeleteProfileOpen,
  deleteProfileOpen
}) {
  var _a, _b;
  const { data: allNetworks, isPending: networksPending } = useAllPublicNetworks();
  const { platform } = useAuth3();
  const { axios } = useApi2();
  const organizationId = platform == null ? void 0 : platform.org.id;
  const { data: organizationProfile, isPending } = useOrgProfile2(organizationId);
  const { mutateAsync: saveOrgProfile } = useEditOrgProfile2(organizationId);
  const { profileClosureEnabled, onboardingMarketingUpdatesConsent } = useFeatureFlags3();
  const { data: notificationSettings, isSuccess } = useGetOrgNotificationSettings3();
  const { mutateAsync: updateEmailNotificationSettings } = useEditOrgNotificationSettings2();
  const { mutateAsync: closeProfile } = useRequestProfileClosure();
  const userEmail = platform == null ? void 0 : platform.user.email;
  const userId = platform == null ? void 0 : platform.user.id;
  const userNetworks = platform == null ? void 0 : platform.org.network_ids;
  const networkDisplayNames = userNetworks == null ? void 0 : userNetworks.map((id) => {
    var _a2;
    return ((_a2 = allNetworks.find((network) => network.id === id)) == null ? void 0 : _a2.name) || `Unknown Network (${id})`;
  }).join(", ");
  const navigate = useNavigate();
  const pluginRegistry = usePluginRegistry2();
  const logout = useCallback(() => {
    navigate("/auth/logout");
    pluginRegistry.events.publish(new AuthClearEvent());
  }, [navigate, pluginRegistry.events]);
  const handleDeleteProfile = () => __async(this, null, function* () {
    const orgId = platform == null ? void 0 : platform.org.id;
    yield closeProfile({ organizationId: orgId != null ? orgId : "" });
    logout();
  });
  const handleSubmit = (values) => __async(this, null, function* () {
    var _a2;
    try {
      if (onboardingMarketingUpdatesConsent && isSuccess && values.marketingUpdatesConsent !== notificationSettings.marketing_updates_consent) {
        yield updateEmailNotificationSettings({
          marketing_updates_consent: values.marketingUpdatesConsent
        });
      }
      const saveOrgProfileVariables = {
        description: values.creatorBio || null,
        public_name: values.displayName || null,
        slug: values.creatorSlug,
        socials: {
          instagram: values.instagramLink || null,
          twitter: values.twitterLink || null,
          discord: values.discordLink || null
        },
        media: {
          banner: values.bannerUrl || null,
          avatar: values.avatarUrl || null
        },
        creator_roles: !((_a2 = values.creatorRoles) == null ? void 0 : _a2.length) ? [OrganizationProfileOutputCreatorRolesEnum.NoneApply] : values.creatorRoles
      };
      if (values.websiteLink) {
        saveOrgProfileVariables.website = values.websiteLink;
      }
      yield saveOrgProfile(saveOrgProfileVariables);
      setEditProfileOpen(false);
    } catch (error) {
      console.error("Profile update failed", error);
    }
  });
  return /* @__PURE__ */ jsxs5(
    Dialog3,
    {
      sx: {
        ".MuiDialog-paper": { minHeight: 300, minWidth: 650 }
      },
      open,
      onClose: handleClose,
      children: [
        /* @__PURE__ */ jsxs5(DialogTitle2, { sx: { justifyContent: "space-between" }, children: [
          /* @__PURE__ */ jsxs5(Stack3, { sx: { flex: 1, display: "flex", flexDirection: "column", rowGap: 1 }, children: [
            /* @__PURE__ */ jsx7(Typography4, { variant: "h4", children: "Account Details" }),
            /* @__PURE__ */ jsx7(Typography4, { variant: "body1", children: "Detailed information about your account. These can be handy to know in the event of any issues." })
          ] }),
          /* @__PURE__ */ jsx7(IconButton3, { "aria-label": "close", onClick: handleClose, size: "large", children: /* @__PURE__ */ jsx7(CloseIcon3, { fontSize: "small" }) })
        ] }),
        /* @__PURE__ */ jsxs5(
          DialogContent3,
          {
            sx: {
              display: "grid",
              gridTemplateColumns: "auto 1fr",
              rowGap: 2,
              columnGap: 2
            },
            children: [
              /* @__PURE__ */ jsx7(AccountDetailsRow, { label: "Registered Email:", value: userEmail }),
              ((_a = platform == null ? void 0 : platform.user) == null ? void 0 : _a.address) && /* @__PURE__ */ jsxs5(Fragment3, { children: [
                /* @__PURE__ */ jsx7(Typography4, { variant: "bodyL", sx: { textAlign: "right" }, children: "Payout Address:" }),
                /* @__PURE__ */ jsx7(Typography4, { variant: "body1", children: (_b = platform == null ? void 0 : platform.user) == null ? void 0 : _b.address })
              ] }),
              !networksPending && /* @__PURE__ */ jsx7(AccountDetailsRow, { label: "Enabled Networks:", value: networkDisplayNames }),
              /* @__PURE__ */ jsx7(AccountDetailsRow, { label: "Organization ID:", value: organizationId }),
              /* @__PURE__ */ jsx7(AccountDetailsRow, { label: "User ID:", value: userId })
            ]
          }
        ),
        /* @__PURE__ */ jsxs5(DialogActions3, { sx: { justifyContent: "end" }, children: [
          profileClosureEnabled && /* @__PURE__ */ jsx7(
            Button3,
            {
              "data-testid": "next",
              variant: "outlined",
              onClick: () => setDeleteProfileOpen(true),
              color: "error",
              sx: {
                "&:hover": {
                  color: "white !important",
                  background: "#ff0000"
                }
              },
              children: "Delete Profile"
            }
          ),
          /* @__PURE__ */ jsx7(
            Button3,
            {
              "data-testid": "next",
              variant: "contained",
              sx: {
                "&.Mui-disabled": {
                  background: "#eaeaea",
                  color: "#c0c0c0"
                },
                color: (theme) => `${theme.palette.primary.main} !important`,
                "&:hover": {
                  color: "white !important"
                }
              },
              onClick: () => setEditProfileOpen(true),
              children: "Edit Profile"
            }
          )
        ] }),
        editProfileOpen && !isPending && /* @__PURE__ */ jsx7(
          CreatorProfileEditForm,
          {
            open: editProfileOpen,
            handleClose,
            handleSubmit,
            organizationProfile,
            axios
          }
        ),
        profileClosureEnabled && deleteProfileOpen && !isPending && /* @__PURE__ */ jsx7(
          CreatorProfileDeleteForm,
          {
            open: deleteProfileOpen,
            handleClose,
            handleDelete: handleDeleteProfile
          }
        )
      ]
    }
  );
}

// src/components/profile-view/CreatorProfileChip.tsx
import { useCreateCreatorProfilePageUri } from "common-api";
import { useAuth as useAuth4 } from "common-ui";
import { ListItemButton as ListItemButton3, Typography as Typography5 } from "@mui/material";

// src/assets/icons/exportIcon.svg
import * as React3 from "react";
import { jsx as jsx8 } from "react/jsx-runtime";
var SvgExportIcon = (props) => /* @__PURE__ */ jsx8("svg", __spreadProps(__spreadValues({ width: 14, height: 14, viewBox: "0 0 14 14", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, props), { children: /* @__PURE__ */ jsx8("path", { d: "M5.5 2.5H2.5C1.67157 2.5 1 3.17157 1 4V11.5C1 12.3284 1.67157 13 2.5 13H10C10.8284 13 11.5 12.3284 11.5 11.5V8.5M8.5 1H13M13 1V5.5M13 1L5.5 8.5", stroke: "#27272A", strokeWidth: 1.5, strokeLinecap: "round", strokeLinejoin: "round" }) }));
var exportIcon_default = SvgExportIcon;

// src/components/profile-view/CreatorProfileChip.tsx
import { jsx as jsx9, jsxs as jsxs6 } from "react/jsx-runtime";
var ViewCreatorProfile = () => {
  const { platform } = useAuth4();
  const organizationId = platform == null ? void 0 : platform.org.id;
  const {
    mutateAsync: createConsumerProfileUri,
    isPending: consumerProfileUriPending,
    data: consumerProfileUriResult
  } = useCreateCreatorProfilePageUri(organizationId);
  return /* @__PURE__ */ jsxs6(
    ListItemButton3,
    {
      sx: {
        display: "flex",
        flexDirection: "row"
      },
      onClick: () => {
        if (consumerProfileUriResult) {
          const uri = (consumerProfileUriResult == null ? void 0 : consumerProfileUriResult.data).absolute;
          if (uri) {
            window.open(uri, "_blank");
          }
        } else {
          createConsumerProfileUri().then((result) => {
            const uri = (result == null ? void 0 : result.data).absolute;
            if (uri) {
              window.open(uri, "_blank");
            }
          });
        }
      },
      children: [
        /* @__PURE__ */ jsx9(exportIcon_default, {}),
        /* @__PURE__ */ jsx9(Typography5, { fontWeight: "600", marginLeft: "12px", children: consumerProfileUriPending ? "Getting Profile ..." : "View Public Profile" })
      ]
    }
  );
};
var CreatorProfileChip_default = ViewCreatorProfile;

// src/plugins/profile-edit/Plugin.tsx
import { jsx as jsx10 } from "react/jsx-runtime";
var ProfileEditPlugin = class extends Plugin {
  get info() {
    return {
      name: "profile-edit",
      title: "Profile Edit",
      disabledByDefault: false
    };
  }
  activate(registry) {
    return __async(this, null, function* () {
      registry.fillSlot(PORTAL_APP_BAR_USER_PROFILE_SLOT.id, () => /* @__PURE__ */ jsx10(CreatorProfileChip_default, {}));
      registry.fillSlot(PORTAL_APP_BAR_USER_PROFILE_SLOT.id, () => /* @__PURE__ */ jsx10(CreatorProfileEdit, {}));
      registry.fillSlot(PORTAL_APP_BAR_USER_PROFILE_SLOT.id, () => /* @__PURE__ */ jsx10(AccountDetails, {}));
      registry.registerCommand({
        id: "portal.profile-edit.open",
        title: "Open Profile Edit",
        handler: () => {
          openProfileEditModal();
        }
      });
      registry.subscribeEvent("api.action", (event) => {
        const { resource, data, type } = event.data;
        if (resource === "ORGANIZATION_PROFILE" && type === "UPDATE") {
          setOrganizationProfileData(data);
        }
      });
    });
  }
};

// src/plugins/require-wallet/Plugin.tsx
import { RequireAuthPage } from "common-ui";
import { Plugin as Plugin2 } from "plugin-system";

// src/components/onboarding/RequireWalletPage.tsx
import { useEffect as useEffect3, useState as useState5 } from "react";
import { useNavigate as useNavigate2 } from "react-router-dom";
import { usePluginRegistry as usePluginRegistry5 } from "plugin-system";
import { useAccount as useAccount3 } from "wagmi";
import { useAuth as useAuth7, useFeatureFlags as useFeatureFlags5 } from "common-ui";

// src/components/onboarding/StepperContainer.tsx
import { Stack as Stack9, Step, StepLabel, Stepper, Typography as Typography11, useTheme } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { OrganizationLogo } from "common-ui";

// src/components/profile-setup/ConnectWallet.tsx
import { Link, Stack as Stack4, Typography as Typography6 } from "@mui/material";
import { usePortalIcon as usePortalIcon2 } from "common-ui";

// src/components/onboarding/WalletConnectButton.tsx
import { Button as Button4, SvgIcon } from "@mui/material";
import { useAccount, useSignMessage } from "wagmi";
import { useCallback as useCallback2, useEffect as useEffect2 } from "react";
import { useAccountModal, useConnectModal } from "@rainbow-me/rainbowkit";
import { useCreateChallengeAuthed, useVerifySignatureAuthed } from "common-api/src/hooks/challenge";
import { useAuth as useAuth5, usePortalIcon } from "common-ui";
import { jsx as jsx11 } from "react/jsx-runtime";
function WalletConnectButton() {
  const { platform } = useAuth5();
  const { signMessageAsync } = useSignMessage();
  const { address, isConnected } = useAccount();
  const { openConnectModal } = useConnectModal();
  const { openAccountModal } = useAccountModal();
  const WalletIcon = usePortalIcon("wallet");
  const FingerprintIcon = usePortalIcon("fingerprint");
  const { mutate: createChallenge, data: createChallengeData } = useCreateChallengeAuthed();
  const { mutate: verifySignature } = useVerifySignatureAuthed();
  const handleVerifyWallet = useCallback2(() => __async(this, null, function* () {
    try {
      if (!address) {
        console.error("User is not connected to the wallet");
        return;
      }
      createChallenge({ body: { address } });
    } catch (error) {
      console.error(error);
    }
  }), [createChallenge, address]);
  const handleSignMessage = useCallback2(() => __async(this, null, function* () {
    const signature = yield signMessageAsync({
      message: createChallengeData ? createChallengeData.message : ""
    });
    verifySignature({
      body: {
        signature,
        challenge_id: createChallengeData ? createChallengeData.id : ""
      }
    });
  }), [createChallengeData, signMessageAsync, verifySignature]);
  useEffect2(() => {
    if (isConnected && createChallengeData) {
      handleSignMessage();
    }
  }, [isConnected, createChallengeData, handleSignMessage]);
  if (!address) {
    return /* @__PURE__ */ jsx11(
      Button4,
      {
        variant: "contained",
        fullWidth: true,
        startIcon: /* @__PURE__ */ jsx11(SvgIcon, { component: WalletIcon, sx: { fontSize: 20 }, inheritViewBox: true }),
        onClick: openConnectModal,
        color: "primary",
        "data-testid": "connect-with-wallet",
        sx: { borderRadius: 2 },
        children: "Connect Wallet"
      }
    );
  }
  if (!(platform == null ? void 0 : platform.user.address)) {
    return /* @__PURE__ */ jsx11(
      Button4,
      {
        variant: "contained",
        fullWidth: true,
        startIcon: /* @__PURE__ */ jsx11(SvgIcon, { component: FingerprintIcon, sx: { fontSize: 20 }, inheritViewBox: true }),
        onClick: handleVerifyWallet,
        "data-testid": "connect-with-wallet",
        sx: { borderRadius: 2 },
        children: "Verify Wallet"
      }
    );
  }
  return /* @__PURE__ */ jsx11(
    Button4,
    {
      variant: "contained",
      fullWidth: true,
      startIcon: /* @__PURE__ */ jsx11(SvgIcon, { component: WalletIcon, sx: { fontSize: 20 }, inheritViewBox: true }),
      onClick: openAccountModal,
      "data-testid": "connect-with-wallet",
      sx: { borderRadius: 2 },
      children: "Disconnect Wallet"
    }
  );
}

// src/components/profile-setup/ConnectWallet.tsx
import { jsx as jsx12, jsxs as jsxs7 } from "react/jsx-runtime";
function ConnectWallet() {
  const WalletIcon = usePortalIcon2("wallet");
  return /* @__PURE__ */ jsxs7(Stack4, { spacing: 6, sx: { width: 400, alignItems: "center", textAlign: "center" }, children: [
    /* @__PURE__ */ jsx12(WalletIcon, {}),
    /* @__PURE__ */ jsxs7(Stack4, { spacing: 3, children: [
      /* @__PURE__ */ jsx12(Typography6, { variant: "h4", fontWeight: 600, children: "Connect your wallet" }),
      /* @__PURE__ */ jsx12(Typography6, { variant: "body1", sx: { color: (theme) => theme.palette.neutral_11 }, children: "Connect your Ethereum wallet to link it to your Phosphor account." }),
      /* @__PURE__ */ jsx12(WalletConnectButton, {}),
      /* @__PURE__ */ jsxs7(Typography6, { variant: "caption", sx: { color: (theme) => theme.palette.neutral_11 }, children: [
        "Need a wallet?",
        " ",
        /* @__PURE__ */ jsx12(
          Link,
          {
            href: "https://metamask.io/download/",
            target: "_blank",
            rel: "noopener noreferrer",
            sx: { textDecoration: "none" },
            children: "Download Metamask"
          }
        )
      ] })
    ] })
  ] });
}

// src/components/profile-setup/VerifyWallet.tsx
import { Alert, Button as Button5, Stack as Stack5, Typography as Typography7 } from "@mui/material";
import { getErrorMessage, shortenString, usePortalIcon as usePortalIcon3 } from "common-ui";
import { useAccount as useAccount2 } from "wagmi";
import { useAccountModal as useAccountModal2 } from "@rainbow-me/rainbowkit";
import { Fragment as Fragment4, jsx as jsx13, jsxs as jsxs8 } from "react/jsx-runtime";
function VerifyWallet({
  error,
  errorMessage
}) {
  const FingerprintIcon = usePortalIcon3("fingerprint");
  return /* @__PURE__ */ jsxs8(Stack5, { spacing: 6, sx: { width: 400, alignItems: "center", textAlign: "center" }, children: [
    /* @__PURE__ */ jsx13(FingerprintIcon, {}),
    /* @__PURE__ */ jsxs8(Stack5, { spacing: 3, children: [
      /* @__PURE__ */ jsx13(Typography7, { variant: "h4", fontWeight: 600, children: "Verify your wallet" }),
      /* @__PURE__ */ jsx13(Typography7, { variant: "body1", children: "Sign a transaction to confirm ownership of your Ethereum wallet." }),
      /* @__PURE__ */ jsxs8(Stack5, { spacing: 2, children: [
        /* @__PURE__ */ jsx13(WalletAddress, {}),
        /* @__PURE__ */ jsx13(WalletConnectButton, {})
      ] }),
      error && /* @__PURE__ */ jsx13(Alert, { severity: "error", children: errorMessage != null ? errorMessage : getErrorMessage("verifying your wallet") })
    ] })
  ] });
}
function WalletAddress() {
  const { address } = useAccount2();
  const { openAccountModal } = useAccountModal2();
  if (address) {
    return /* @__PURE__ */ jsx13(Button5, { variant: "outlined", fullWidth: true, onClick: openAccountModal, sx: { borderRadius: 2 }, children: shortenString(address) });
  }
  return /* @__PURE__ */ jsx13(Fragment4, {});
}

// src/components/profile-setup/Complete.tsx
import { Stack as Stack6, Typography as Typography8 } from "@mui/material";
import { usePortalIcon as usePortalIcon4 } from "common-ui";
import { jsx as jsx14, jsxs as jsxs9 } from "react/jsx-runtime";
function Complete() {
  const SuccessIcon = usePortalIcon4("success");
  return /* @__PURE__ */ jsxs9(Stack6, { spacing: 6, sx: { width: 400, alignItems: "center", textAlign: "center" }, children: [
    /* @__PURE__ */ jsx14(SuccessIcon, {}),
    /* @__PURE__ */ jsxs9(Stack6, { spacing: 3, children: [
      /* @__PURE__ */ jsx14(Typography8, { variant: "h4", fontWeight: 600, children: "You're all set!" }),
      /* @__PURE__ */ jsx14(Typography8, { variant: "body1", children: "Thank you for adding your Ethereum address. Welcome to Phosphor!" })
    ] })
  ] });
}

// src/components/profile-setup/AgreeToTerms.tsx
import { useSnapshot as useSnapshot2 } from "valtio";
import { Alert as Alert2, Box as Box3, Button as Button6, Checkbox as Checkbox4, Link as Link2, Stack as Stack7, Typography as Typography9 } from "@mui/material";
import { useFormik } from "formik";
import { toFormikValidationSchema as toFormikValidationSchema2 } from "zod-formik-adapter";
import { useEditOrgNotificationSettings as useEditOrgNotificationSettings3, useEditOrgProfile as useEditOrgProfile3 } from "common-api";
import { usePluginRegistry as usePluginRegistry3 } from "plugin-system";
import { getErrorMessage as getErrorMessage2, useAuth as useAuth6, useFeatureFlags as useFeatureFlags4, usePortalIcon as usePortalIcon5 } from "common-ui";

// src/state/profile-edit/profile-setup/username.ts
import { proxy as proxy2 } from "valtio";
import * as z2 from "zod";
var validationSchema2 = (validateSlugAvailability) => z2.object({
  username: z2.string({ required_error: "Username is required." }).min(5, { message: "Username must contain at least 5 characters" }).max(75).regex(
    /^[a-zA-Z0-9._-]+$/,
    "Username can only contain letters, numbers, underscores, periods and hyphens."
  ).refine(
    (val) => __async(void 0, null, function* () {
      const { isAvailable } = yield validateSlugAvailability(val);
      if (isAvailable) {
        return true;
      }
      return false;
    }),
    { message: "The username is not available for use." }
  ),
  profilePic: z2.string().url().optional(),
  displayName: z2.string().optional(),
  preventCancel: z2.boolean(),
  preventSubmit: z2.boolean(),
  preventSubmitLoading: z2.boolean()
});
var defaultStateValues = {
  username: "",
  profilePic: "",
  displayName: "",
  preventCancel: false,
  preventSubmit: false,
  preventSubmitLoading: false
};
var state2 = proxy2(__spreadValues({}, defaultStateValues));
var username_default = state2;

// src/state/profile-edit/profile-setup/consent.ts
import { proxy as proxy3 } from "valtio";
import * as z3 from "zod";
var validationSchema3 = z3.object({
  termsConsent: z3.boolean().refine((v) => !!v, { message: "You must agree to the terms to continue." }),
  marketingConsent: z3.boolean().optional()
});
var defaultStateValues2 = {
  termsConsent: false,
  marketingConsent: false
};
var state3 = proxy3(__spreadValues({}, defaultStateValues2));

// src/components/profile-setup/AgreeToTerms.tsx
import { jsx as jsx15, jsxs as jsxs10 } from "react/jsx-runtime";
function AgreeToTerms({
  error,
  errorMessage
}) {
  var _a;
  const usernameSnap = useSnapshot2(state2);
  const TermsIcon = usePortalIcon5("terms");
  const { onboardingMarketingUpdatesConsent } = useFeatureFlags4();
  const { platform } = useAuth6();
  const editOrgProfile = useEditOrgProfile3((_a = platform == null ? void 0 : platform.org) == null ? void 0 : _a.id);
  const updateEmailNotificationSettings = useEditOrgNotificationSettings3();
  const pluginRegistry = usePluginRegistry3();
  const formik = useFormik({
    initialValues: defaultStateValues2,
    enableReinitialize: true,
    validateOnBlur: false,
    validationSchema: toFormikValidationSchema2(validationSchema3),
    onSubmit: (values) => __async(this, null, function* () {
      var _a2, _b, _c;
      try {
        if (values.marketingConsent) {
          yield updateEmailNotificationSettings.mutateAsync({
            enabled: true,
            marketing_updates_consent: true
          });
        }
        yield editOrgProfile.mutateAsync({
          slug: (_a2 = usernameSnap.username) != null ? _a2 : null,
          public_name: (_b = usernameSnap.displayName) != null ? _b : null,
          media: {
            avatar: (_c = usernameSnap.profilePic) != null ? _c : null
          }
        });
        pluginRegistry.events.publish({ id: "agree-to-terms.success" });
      } catch (error2) {
        pluginRegistry.events.publish({ id: "agree-to-terms.error" });
      }
    })
  });
  return /* @__PURE__ */ jsxs10(Stack7, { spacing: 6, sx: { width: 400, alignItems: "center", textAlign: "center" }, children: [
    /* @__PURE__ */ jsx15(TermsIcon, {}),
    /* @__PURE__ */ jsx15("form", { onSubmit: formik.handleSubmit, style: { width: "100%" }, children: /* @__PURE__ */ jsxs10(Stack7, { spacing: 2, sx: { width: "100%" }, children: [
      /* @__PURE__ */ jsx15(Typography9, { variant: "h4", fontWeight: 600, children: "Agree to our terms" }),
      /* @__PURE__ */ jsx15(Typography9, { variant: "body1", children: "Read and agree to our terms and conditions below." }),
      /* @__PURE__ */ jsxs10(Box3, { display: "flex", alignItems: "center", children: [
        /* @__PURE__ */ jsx15(
          Checkbox4,
          {
            checked: formik.values.termsConsent,
            id: "termsConsent",
            name: "termsConsent",
            onBlur: formik.handleBlur,
            onChange: formik.handleChange
          }
        ),
        /* @__PURE__ */ jsxs10(Typography9, { variant: "body2", textAlign: "left", children: [
          "I have read and agree to ConsenSys'",
          " ",
          /* @__PURE__ */ jsx15(Link2, { href: "https://consensys.io/privacy-policy/", target: "_blank", rel: "noopener", children: "Privacy Policy" }),
          " ",
          "and",
          " ",
          /* @__PURE__ */ jsx15(Link2, { href: "https://consensys.io/terms-of-use/", target: "_blank", rel: "noopener", children: "Terms of Use" }),
          "."
        ] })
      ] }),
      onboardingMarketingUpdatesConsent && /* @__PURE__ */ jsxs10(Box3, { display: "flex", alignItems: "center", children: [
        /* @__PURE__ */ jsx15(
          Checkbox4,
          {
            checked: formik.values.marketingConsent,
            id: "marketingConsent",
            name: "marketingConsent",
            onBlur: formik.handleBlur,
            onChange: formik.handleChange
          }
        ),
        /* @__PURE__ */ jsx15(Typography9, { variant: "body2", textAlign: "left", children: "Keep me informed with the Phosphor newsletter." })
      ] }),
      /* @__PURE__ */ jsx15(
        Button6,
        {
          variant: "contained",
          type: "submit",
          fullWidth: true,
          "data-testid": "continue-profile-setup",
          disabled: formik.isSubmitting || !formik.isValid || !formik.values["termsConsent"] || formik.isValidating,
          sx: { borderRadius: 2 },
          children: "Continue"
        }
      )
    ] }) }),
    error && /* @__PURE__ */ jsx15(Alert2, { severity: "error", children: errorMessage != null ? errorMessage : getErrorMessage2("creating your profile") })
  ] });
}

// src/components/profile-setup/CreateProfile.tsx
import {
  Alert as Alert3,
  Button as Button7,
  CircularProgress as CircularProgress2,
  InputAdornment as InputAdornment2,
  Stack as Stack8,
  TextField as TextField3,
  Typography as Typography10
} from "@mui/material";
import {
  COMMON_IMAGE_MIME_TYPES as COMMON_IMAGE_MIME_TYPES2,
  CommonInputLabelStack as CommonInputLabelStack2,
  FORM_PREVENT_CANCEL as FORM_PREVENT_CANCEL2,
  FORM_PREVENT_SUBMIT as FORM_PREVENT_SUBMIT2,
  FORM_PREVENT_SUBMIT_LOADING as FORM_PREVENT_SUBMIT_LOADING2,
  getErrorMessage as getErrorMessage3,
  SingleFileServerUpload as SingleFileServerUpload2,
  usePortalIcon as usePortalIcon6
} from "common-ui";
import { useFormik as useFormik2 } from "formik";
import { toFormikValidationSchema as toFormikValidationSchema3 } from "zod-formik-adapter";
import { useApi as useApi3, useValidateOrgSlugAvailability as useValidateOrgSlugAvailability2 } from "common-api";
import { usePluginRegistry as usePluginRegistry4 } from "plugin-system";
import { Fragment as Fragment5, jsx as jsx16, jsxs as jsxs11 } from "react/jsx-runtime";
function CreateProfile({
  error,
  errorMessage
}) {
  const ProfileIcon = usePortalIcon6("profile");
  const { axios } = useApi3();
  const fileUploadUri = axios ? (axios == null ? void 0 : axios.defaults.baseURL) + "/uploads" : "";
  const { mutateAsync: validateSlugAvailability } = useValidateOrgSlugAvailability2();
  const pluginRegistry = usePluginRegistry4();
  const formik = useFormik2({
    initialValues: defaultStateValues,
    enableReinitialize: true,
    validateOnBlur: false,
    validationSchema: toFormikValidationSchema3(validationSchema2(validateSlugAvailability)),
    onSubmit: (values) => {
      var _a, _b, _c;
      username_default.displayName = (_a = values.displayName) != null ? _a : void 0;
      username_default.profilePic = (_b = values.profilePic) != null ? _b : void 0;
      username_default.username = (_c = values.username) != null ? _c : void 0;
      pluginRegistry.events.publish({
        id: "create-profile.success"
      });
    }
  });
  return /* @__PURE__ */ jsxs11(Stack8, { spacing: 6, sx: { width: 400, alignItems: "center", textAlign: "center" }, children: [
    /* @__PURE__ */ jsx16(ProfileIcon, {}),
    /* @__PURE__ */ jsx16("form", { onSubmit: formik.handleSubmit, style: { width: "100%" }, children: /* @__PURE__ */ jsxs11(Stack8, { spacing: 2, sx: { width: "100%" }, children: [
      /* @__PURE__ */ jsx16(Typography10, { variant: "h4", fontWeight: 600, children: "Create your profile" }),
      /* @__PURE__ */ jsx16(Typography10, { variant: "body1", children: "Tell the world all about yourself." }),
      /* @__PURE__ */ jsxs11(Stack8, { children: [
        /* @__PURE__ */ jsx16(
          CommonInputLabelStack2,
          {
            forCtrl: "custom",
            label: "Profile picture",
            isOptional: true,
            variant: "body1"
          }
        ),
        /* @__PURE__ */ jsx16(
          SingleFileServerUpload2,
          {
            testId: "profile-picture-upload",
            height: "200px",
            width: "200px",
            titleLabel: "Upload a file",
            maxFileSize: "2MB",
            initialValue: formik.values["profilePic"],
            disabled: formik.isSubmitting,
            acceptedFileTypes: COMMON_IMAGE_MIME_TYPES2,
            uploadUri: fileUploadUri,
            uploadAuthHeader: axios == null ? void 0 : axios.defaults.headers.Authorization,
            onFileUploadedUrlChange: (organizationBannerUrl) => {
              formik.setFieldValue("profilePic", organizationBannerUrl, true);
              formik.setFieldTouched("profilePic", true, false);
            },
            onUploadingChange: (isUploading) => {
              formik.setFieldValue(FORM_PREVENT_SUBMIT_LOADING2, isUploading, false);
              formik.setFieldValue(FORM_PREVENT_CANCEL2, isUploading, false);
            },
            allowImageCrop: true,
            imageCropAspectRatio: "1:1"
          }
        )
      ] }),
      /* @__PURE__ */ jsx16(
        TextField3,
        {
          fullWidth: true,
          id: "username",
          name: "username",
          type: "text",
          "data-testid": "profile-usename",
          label: /* @__PURE__ */ jsx16(CommonInputLabelStack2, { label: "Username", variant: "body1" }),
          autoFocus: true,
          autoComplete: "off",
          value: formik.values["username"],
          onChange: formik.handleChange,
          onBlur: formik.handleBlur,
          error: Boolean(formik.errors["username"]),
          disabled: formik.isSubmitting,
          sx: { ".MuiInputBase-root": { p: 0 } },
          InputProps: {
            readOnly: formik.isSubmitting,
            startAdornment: /* @__PURE__ */ jsx16(
              InputAdornment2,
              {
                position: "start",
                sx: {
                  height: "100%",
                  maxHeight: "100%",
                  p: 2,
                  backgroundColor: "neutral_50",
                  borderColor: "neutral_100",
                  borderWidth: "0 1px 0 0",
                  borderStyle: "solid",
                  borderTopLeftRadius: 5,
                  borderBottomLeftRadius: 5
                },
                children: "phosphor.xyz/"
              }
            ),
            endAdornment: formik.values[FORM_PREVENT_SUBMIT2] && !formik.errors["username"] ? /* @__PURE__ */ jsx16(InputAdornment2, { position: "end", sx: { mr: 1 }, children: /* @__PURE__ */ jsx16(CircularProgress2, { size: 18 }) }) : /* @__PURE__ */ jsx16(Fragment5, {})
          },
          helperText: formik.errors["username"] ? formik.errors["username"] : /* @__PURE__ */ jsx16(Fragment5, { children: "Alphanumeric only, no caps, spaces, or special characters" })
        }
      ),
      /* @__PURE__ */ jsx16(
        TextField3,
        {
          fullWidth: true,
          id: "displayName",
          name: "displayName",
          type: "text",
          inputProps: {
            readOnly: formik.isSubmitting
          },
          autoComplete: "off",
          "data-testid": "profile-display-name",
          label: /* @__PURE__ */ jsx16(CommonInputLabelStack2, { label: "Display Name", isOptional: true, variant: "body1" }),
          autoFocus: true,
          value: formik.values.displayName,
          onChange: formik.handleChange,
          onBlur: formik.handleBlur,
          error: formik.touched["displayName"] && Boolean(formik.errors["displayName"]),
          disabled: formik.isSubmitting,
          helperText: formik.touched["displayName"] && formik.errors["displayName"]
        }
      ),
      /* @__PURE__ */ jsx16(
        Button7,
        {
          variant: "contained",
          type: "submit",
          fullWidth: true,
          "data-testid": "continue-profile-setup",
          disabled: formik.isSubmitting || formik.values[FORM_PREVENT_SUBMIT2] === true || !formik.isValid || !formik.values["username"] || formik.isValidating,
          sx: { borderRadius: 2 },
          children: "Continue"
        }
      )
    ] }) }),
    error && /* @__PURE__ */ jsx16(Alert3, { severity: "error", children: errorMessage != null ? errorMessage : getErrorMessage3("creating your profile") })
  ] });
}

// src/components/onboarding/StepperContainer.tsx
import { jsx as jsx17, jsxs as jsxs12 } from "react/jsx-runtime";
function StepperContainer({
  title,
  subtitle,
  steps,
  activeStep,
  error,
  errorMessage
}) {
  const theme = useTheme();
  return /* @__PURE__ */ jsxs12(Grid, { container: true, height: "100vh", width: "100vw", sx: { minWidth: 1200 }, children: [
    /* @__PURE__ */ jsx17(
      Grid,
      {
        xs: 5,
        sx: {
          backgroundColor: "neutral_50",
          p: 12,
          display: "flex",
          alignItems: "center",
          height: "100%"
        },
        children: /* @__PURE__ */ jsxs12(Stack9, { spacing: 6, children: [
          /* @__PURE__ */ jsx17(OrganizationLogo, {}),
          /* @__PURE__ */ jsxs12(Stack9, { spacing: 2, children: [
            /* @__PURE__ */ jsx17(Typography11, { variant: "h4", sx: { fontWeight: 500 }, children: title }),
            /* @__PURE__ */ jsx17(Typography11, { variant: "body1", sx: { color: "neutral_11" }, children: subtitle }),
            /* @__PURE__ */ jsx17(
              Stepper,
              {
                activeStep,
                orientation: "vertical",
                sx: {
                  ".MuiStepConnector-line": {
                    marginLeft: 1,
                    borderWidth: "0 0 0 1px",
                    borderStyle: "solid",
                    borderColor: "gray",
                    minHeight: 20,
                    marginTop: -1
                  }
                },
                children: steps.map((step, index) => /* @__PURE__ */ jsx17(Step, { expanded: true, children: /* @__PURE__ */ jsx17(
                  StepLabel,
                  {
                    sx: {
                      display: "flex",
                      alignItems: "start",
                      ".MuiSvgIcon-root": {
                        color: "white",
                        height: 40,
                        width: 40,
                        border: "1px solid gray",
                        borderRadius: "50%",
                        "&.Mui-active": {
                          color: error ? "pink" : "white",
                          borderWidth: error ? 0 : 2,
                          borderStyle: "solid",
                          borderColor: "secondary_500"
                        },
                        "&.Mui-completed": {
                          color: "secondary_500",
                          fill: theme.palette.secondary_500,
                          border: "none"
                        }
                      },
                      ".MuiStepIcon-text": { color: "text.primary", fill: "gray" }
                    },
                    children: /* @__PURE__ */ jsxs12(Stack9, { spacing: 1, sx: { ml: 2 }, children: [
                      /* @__PURE__ */ jsx17(Typography11, { variant: "body1", fontWeight: 600, children: step.label }),
                      /* @__PURE__ */ jsx17(Typography11, { variant: "body2", sx: { color: "neutral_11" }, children: step.description })
                    ] })
                  }
                ) }, step.label))
              }
            )
          ] })
        ] })
      }
    ),
    /* @__PURE__ */ jsx17(
      Grid,
      {
        xs: 7,
        sx: {
          p: 12,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "100%"
        },
        children: /* @__PURE__ */ jsx17(
          StepContent,
          {
            step: activeStep === steps.length ? "COMPLETE" : steps[activeStep].name,
            error,
            errorMessage
          }
        )
      }
    )
  ] });
}
function StepContent({
  step,
  error,
  errorMessage
}) {
  switch (step) {
    case "CONNECT_WALLET":
      return /* @__PURE__ */ jsx17(ConnectWallet, {});
    case "VERIFY_WALLET":
      return /* @__PURE__ */ jsx17(VerifyWallet, { error, errorMessage });
    case "CREATE_PROFILE":
      return /* @__PURE__ */ jsx17(CreateProfile, { error, errorMessage });
    case "AGREE_TO_TERMS":
      return /* @__PURE__ */ jsx17(AgreeToTerms, { error, errorMessage });
    case "COMPLETE":
      return /* @__PURE__ */ jsx17(Complete, {});
  }
}

// src/components/onboarding/RequireWalletPage.tsx
import { jsx as jsx18 } from "react/jsx-runtime";
var connectWalletSteps = [
  {
    name: "CONNECT_WALLET",
    label: "Connect your wallet",
    description: "Link your Ethereum wallet to your account."
  },
  {
    name: "VERIFY_WALLET",
    label: "Verify your wallet",
    description: "Sign a transaction to confirm ownership."
  },
  {
    name: "COMPLETE",
    label: "Finish up",
    description: "You are all set."
  }
];
function RequireWalletPage() {
  const { platform } = useAuth7();
  const navigate = useNavigate2();
  const pluginRegistry = usePluginRegistry5();
  const { address } = useAccount3();
  const { consumerCreatorAccount } = useFeatureFlags5();
  const [activeStep, setActiveStep] = useState5(0);
  const [error, setError] = useState5(false);
  const [errorMessage, setErrorMessage] = useState5("");
  useEffect3(() => {
    if (!address) {
      setActiveStep(0);
    } else if (!(platform == null ? void 0 : platform.user.address)) {
      setActiveStep(1);
    } else {
      setActiveStep(connectWalletSteps.length);
    }
  }, [address, platform == null ? void 0 : platform.user.address]);
  useEffect3(() => {
    return pluginRegistry.events.subscribe("api.action.create-challenge.error", (e) => {
      setActiveStep(1);
      setError(true);
    });
  }, [pluginRegistry.events]);
  useEffect3(() => {
    return pluginRegistry.events.subscribe("api.action.verify-signature.error", (e) => {
      var _a, _b, _c, _d, _e;
      setActiveStep(1);
      setError(true);
      setErrorMessage((_e = (_d = (_c = (_b = (_a = e.data) == null ? void 0 : _a.error) == null ? void 0 : _b.response) == null ? void 0 : _c.data) == null ? void 0 : _d.error) == null ? void 0 : _e.detail);
    });
  }, [pluginRegistry.events]);
  useEffect3(() => {
    return pluginRegistry.events.subscribe("api.action.verify-signature.success", (e) => {
      setActiveStep(connectWalletSteps.length);
    });
  }, [pluginRegistry.events]);
  useEffect3(() => {
    let handler;
    if (!consumerCreatorAccount || (platform == null ? void 0 : platform.user.address) || activeStep === connectWalletSteps.length) {
      handler = setTimeout(() => {
        navigate("/");
      }, 2e3);
    }
    return () => clearTimeout(handler);
  }, [activeStep, consumerCreatorAccount, navigate, platform == null ? void 0 : platform.user.address]);
  return /* @__PURE__ */ jsx18(
    StepperContainer,
    {
      activeStep,
      error,
      errorMessage,
      steps: connectWalletSteps,
      title: "To continue using Phosphor, please add your Ethereum address.",
      subtitle: "We're updating the way accounts work across Phosphor. Provide an Ethereum address\n              to continue."
    }
  );
}

// src/plugins/require-wallet/Plugin.tsx
import { jsx as jsx19 } from "react/jsx-runtime";
var RequireWalletPlugin = class extends Plugin2 {
  get info() {
    return {
      name: "connect-wallet",
      title: "Connect Wallet",
      disabledByDefault: false
    };
  }
  activate(registry) {
    return __async(this, null, function* () {
      registry.fillSlot("shell.routes", {
        path: "/connect-wallet",
        element: /* @__PURE__ */ jsx19(RequireAuthPage, { children: /* @__PURE__ */ jsx19(RequireWalletPage, {}) })
      });
    });
  }
};

// src/plugins/profile-setup/Plugin.tsx
import { RequireAuthPage as RequireAuthPage2 } from "common-ui";
import { Plugin as Plugin3 } from "plugin-system";

// src/components/onboarding/OnboardingPage.tsx
import { useNavigate as useNavigate3 } from "react-router-dom";
import { usePluginRegistry as usePluginRegistry6 } from "plugin-system";
import { useEffect as useEffect4, useMemo as useMemo2, useState as useState6 } from "react";
import { useCurrentPlatformUser, useOrgProfile as useOrgProfile3 } from "common-api";
import { useAuth as useAuth8, useFeatureFlags as useFeatureFlags6 } from "common-ui";
import { useAccount as useAccount4 } from "wagmi";
import { jsx as jsx20 } from "react/jsx-runtime";
var profileSetupSteps = [
  {
    name: "CREATE_PROFILE",
    label: "Create your profile",
    description: "Tell us about yourself, starting with a profile."
  },
  {
    name: "AGREE_TO_TERMS",
    label: "Agree to our terms",
    description: "Read and agree to our terms and conditions."
  }
];
function OnboardingPage() {
  var _a, _b;
  const { platform, token } = useAuth8();
  const navigate = useNavigate3();
  const pluginRegistry = usePluginRegistry6();
  const { address } = useAccount4();
  const { consumerCreatorAccount } = useFeatureFlags6();
  const [activeStep, setActiveStep] = useState6(0);
  const [error, setError] = useState6(false);
  const [errorMessage, setErrorMessage] = useState6("");
  const onboardingSteps = useMemo2(() => {
    return consumerCreatorAccount ? [...connectWalletSteps.slice(0, 2), ...profileSetupSteps, ...connectWalletSteps.slice(-1)] : profileSetupSteps;
  }, [consumerCreatorAccount]);
  const { isSuccess: orgProfileSuccess, data: orgProfile } = useOrgProfile3((_a = platform == null ? void 0 : platform.org) == null ? void 0 : _a.id);
  const { data: platformUser } = useCurrentPlatformUser(token == null ? void 0 : token.accessToken);
  useEffect4(() => {
    var _a2;
    if (consumerCreatorAccount) {
      if (!address) {
        setActiveStep(0);
      } else if (!((_a2 = platformUser == null ? void 0 : platformUser.user) == null ? void 0 : _a2.address)) {
        setActiveStep(1);
      } else if (orgProfileSuccess && !(orgProfile == null ? void 0 : orgProfile.slug)) {
        setActiveStep(2);
      } else {
        setActiveStep(onboardingSteps.length);
      }
    } else {
      if (orgProfileSuccess && !(orgProfile == null ? void 0 : orgProfile.slug)) {
        setActiveStep(0);
      } else {
        setActiveStep(onboardingSteps.length);
      }
    }
  }, [
    consumerCreatorAccount,
    address,
    orgProfile == null ? void 0 : orgProfile.slug,
    orgProfileSuccess,
    onboardingSteps.length,
    (_b = platformUser == null ? void 0 : platformUser.user) == null ? void 0 : _b.address
  ]);
  useEffect4(() => {
    return pluginRegistry.events.subscribe("api.action.create-challenge.error", (e) => {
      setActiveStep(1);
      setError(true);
    });
  }, [pluginRegistry.events]);
  useEffect4(() => {
    return pluginRegistry.events.subscribe("api.action.verify-signature.error", (e) => {
      var _a2, _b2, _c, _d, _e;
      setActiveStep(1);
      setError(true);
      setErrorMessage((_e = (_d = (_c = (_b2 = (_a2 = e.data) == null ? void 0 : _a2.error) == null ? void 0 : _b2.response) == null ? void 0 : _c.data) == null ? void 0 : _d.error) == null ? void 0 : _e.detail);
    });
  }, [pluginRegistry.events]);
  useEffect4(() => {
    return pluginRegistry.events.subscribe("api.action.verify-signature.success", (e) => {
      setActiveStep(2);
      setError(false);
    });
  }, [pluginRegistry.events]);
  useEffect4(() => {
    return pluginRegistry.events.subscribe("create-profile.success", (e) => {
      setActiveStep(3);
      setError(false);
    });
  }, [pluginRegistry.events]);
  useEffect4(() => {
    return pluginRegistry.events.subscribe("agree-to-terms.error", (e) => {
      var _a2, _b2, _c, _d, _e;
      setActiveStep(2);
      setError(true);
      setErrorMessage((_e = (_d = (_c = (_b2 = (_a2 = e.data) == null ? void 0 : _a2.error) == null ? void 0 : _b2.response) == null ? void 0 : _c.data) == null ? void 0 : _d.error) == null ? void 0 : _e.detail);
    });
  }, [pluginRegistry.events]);
  useEffect4(() => {
    return pluginRegistry.events.subscribe("agree-to-terms.success", (e) => {
      setActiveStep(4);
      setError(false);
    });
  }, [pluginRegistry.events]);
  useEffect4(() => {
    let handler;
    if (activeStep === onboardingSteps.length) {
      handler = setTimeout(() => {
        navigate("/");
      }, 2e3);
    }
    return () => clearTimeout(handler);
  }, [activeStep, consumerCreatorAccount, navigate, onboardingSteps.length, platform == null ? void 0 : platform.user.address]);
  return /* @__PURE__ */ jsx20(
    StepperContainer,
    {
      activeStep,
      error,
      errorMessage,
      steps: onboardingSteps,
      title: "Welcome to Phosphor Creator",
      subtitle: "We're excited to have you join us. Follow these steps to set up your account and start creating your NFT drops."
    }
  );
}

// src/plugins/profile-setup/Plugin.tsx
import { jsx as jsx21 } from "react/jsx-runtime";
var ProfileSetupPlugin = class extends Plugin3 {
  get info() {
    return {
      name: "profile-setup",
      title: "Profile Setup",
      disabledByDefault: false
    };
  }
  activate(registry) {
    return __async(this, null, function* () {
      registry.fillSlot("shell.routes", {
        path: "/setup",
        element: /* @__PURE__ */ jsx21(RequireAuthPage2, { children: /* @__PURE__ */ jsx21(OnboardingPage, {}) })
      });
    });
  }
};
export {
  ProfileEditPlugin,
  ProfileSetupPlugin,
  RequireWalletPlugin
};
